<template>
    <div class="good-broad-caster">
        <el-table :data="tbGoodBroadCasterData"
                  :header-cell-style="{fontWeight: 'normal',color: '#333'}" style="width: 100%; min-height: calc(100vh - 380px);">
            <el-table-column label="播主" width="200">
                <template slot-scope="scope">
                    <div class="goods-detail" @click="changTo(scope.row)" style="cursor: pointer;">
                        <div class="goods-img">
                            <img :src="scope.row.avatar_medium_url ? scope.row.avatar_medium_url : require('../../assets/img/image/user_img.png')" alt="">
                        </div>
                        <div class="goods-info">
                            <span class="user-detail">{{ scope.row.name }}</span>
                            <span class="fans-num">粉丝数：{{ scope.row.fans_num }}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="label" label="分类" align="center" min-width="100"></el-table-column>
            <el-table-column prop="volume" label="预估销量" align="center" min-width="100"></el-table-column>
            <el-table-column prop="amount" label="预估销售额" align="center" min-width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.amount}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="aweme_count" label="关联视频数"  align="center" min-width="100"></el-table-column>
            <el-table-column prop="room_count" label="关联直播数"  align="center" min-width="100"></el-table-column>
<!--            <el-table-column label="详情" type="expand" prop="broadVideoList" min-width="200">-->
<!--                <template slot-scope="scope">-->
<!--                    <el-table :data="scope.row.video_detail" :header-cell-style="{fontWeight: 'normal',color: '#333'}">-->
<!--                        <el-table-column label="视频" min-width="400">-->
<!--                            <template slot-scope="scope">-->
<!--                                <a :href="scope.row.video_url" target="_blank"  class="goods-video-detail">-->
<!--                                    <div class="goods-img">-->
<!--                                        <img :src="scope.row.video_cover" alt="">-->
<!--                                    </div>-->
<!--                                    <div class="goods-info">-->
<!--                                        <div class="goods-link" :title="scope.row.videoLink">-->
<!--                                            {{ scope.row.video_title }}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </a>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                        <el-table-column prop="dianzan_num" label="点赞" align="center" min-width="150"></el-table-column>-->
<!--                        <el-table-column prop="video_time" label="视频秒速" align="center" min-width="150"></el-table-column>-->
<!--                        <el-table-column label="操作" align="center" min-width="100">-->
<!--                            <template slot-scope="scope">-->
<!--                                <el-button type="primary" circle size="small" @click="toVideoDetail(scope.row)">-->
<!--                                    <i class="iconfont">&#xe6a1;</i>-->
<!--                                </el-button>-->
<!--                                <a :href="scope.row.video_url" target="_blank" style="color: #fff; margin-left: 10px">-->
<!--                                    <el-button type="danger" circle size="small">-->
<!--                                        <i class="iconfont">&#xe63a;</i>-->
<!--                                    </el-button>-->
<!--                                </a>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
<!--                    </el-table>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>
    </div>
</template>

<script>
    export default {
        name: "GoodBroadCaster",
        data() {
            return {
                goodsId: Number(this.$route.query.id) || '',
                tbGoodBroadCasterData: [],
            }
        },
        mounted() {
            this.getTbBroadList()
        },
        methods: {
            // 淘宝的在线播主分析
            getTbBroadList() {
                let params = {
                    id: this.goodsId
                }
                this.$httpStudent.axiosGetBy(this.$api.accoutData, params, (res) => {
                    if (res.code === 200) {
                        if (res.info) {
                            this.tbGoodBroadCasterData = res.info
                        } else {
                            this.tbGoodBroadCasterData = []
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            toVideoDetail(row) {
                this.$router.push({
                    path: '/student/ds/video/details',
                    query: {
                        aweme_id: row.video_id
                    }
                })
            },
            changTo(row){
                this.$router.push({
                    path: "/student/hot/authorDetail",
                    query: {
                        author_id: row.author_id,
                    }
                })
            },

        }
    }
</script>

<style scoped lang="scss">
    .good-broad-caster {
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .goods-detail {
            display: flex;
            align-items: center;
            .goods-img {
                width: 34px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-info {
                display: flex;
                flex-direction: column;
                .fans-num {
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
        .goods-video-detail {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #333;
            &:hover {
                color: #1E33E3;
            }
            .goods-img {
                width: 64px;
                height: 89px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-info {
                width: calc(100% - 74px);
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                a {
                    color: #1A1A1A;
                }
            }
        }
    }
    .pages-center {
        width: 100%;
        flex: 1 1 0%;
        height: calc(100% - 52px);
    }
</style>