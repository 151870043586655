<template>
    <div class="promoteGood-container">
        <el-table :data="hostVideoList" style="width: 100%; min-height: calc(100vh - 380px);" size="medium" class="listTable"
                  :header-cell-style="{fontWeight: 'normal', color: '#333'}" :cell-style="{fontSize: '14px',color: '#333'}">
            <el-table-column label="视频" min-width="300">
                <template slot-scope="scope">
                    <a :href="scope.row.video_cover_url" target="_blank" class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.share_url" alt="">
                        </div>
                        <div class="goods-title text-overflow" :title="scope.row.video_name">{{scope.row.video_name}}</div>
                    </a>
                </template>
            </el-table-column>
            <el-table-column label="播主" min-width="150">
                <template slot-scope="scope">
                    <a :href="scope.row.author_url" target="_blank" class="author-name" :title="scope.row.author_name">{{scope.row.author_name}}</a>
                    <p class="author-fun">粉丝数 {{scope.row.follower_count}}</p>
                </template>
            </el-table-column>
            <el-table-column prop="video_dianzan_num2" label="点赞数" sortable align="center" min-width="90"></el-table-column>
            <el-table-column prop="video_pinglun_num2" label="评论数" sortable align="center" min-width="90"></el-table-column>
            <el-table-column prop="video_create_time" label="发布时间" sortable align="center" min-width="200"></el-table-column>
            <el-table-column label="操作" align="center" min-width="120" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-s-data" circle @click="toVideoDetail(scope.row)"></el-button>
                    <a :href="scope.row.video_cover_url" target="_blank" style="color: #fff; margin-left: 10px">
                        <el-button type="success" icon="el-icon-caret-right" circle></el-button>
                    </a>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        // props: ['pageType'],
        data() {
            return {
                goodsId: Number(this.$route.query.id) || '',
                hostVideoList: [],
                dyHostVideoList: [],
            }
        },
        mounted() {
            this.getPromoteVideo()
        },
        methods: {
            getPromoteVideo() {
                let params = {
                    id: this.goodsId
                }
                this.$httpStudent.axiosGetBy(this.$api.goodsVideosData, params, (res) => {
                    if (res.code === 200) {
                        if (res.info.goods_video) {
                            this.hostVideoList = res.info.goods_video
                        } else {
                            this.hostVideoList = []
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            toVideoDetail(row) {
                this.$router.push({
                    // path: '/student/hot/videoDetail',
                    // query: {
                    //     videoId: row.video_id
                    // },
                    path: '/student/ds/video/details',
                    query: {
                        aweme_id: row.video_id
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .promoteGood-container {
        padding: 20px;
        /*::v-deep .el-table__empty-block {*/
        /*    height: calc(100vh - 380px - 55px)!important;*/
        /*}*/
    }
    // 表格内样式
    .listTable {
        .goods-wrapper {
            display: flex;
            align-items: center;
            .goods-cover {
                width: 80px;
                height: 104px;
                overflow: hidden;
                background: #f3f3f6;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .goods-title {
                width: 1%;
                flex: 1;
                margin-left: 10px;
                color: #333;
            }
            &:hover {
                .goods-title {
                    color: #1E33E3;
                }
            }
        }
        .author-name {
            white-space: nowrap;
            color: #2338E6;
        }
    }
</style>